// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, List, Modal, Radio, Row, Spin, Switch, Typography, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";
import SelectSearchComponent from "../../../components/SelectSearchComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

// icons
import { FaCheck } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";

const OrcamentosForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID } = useParams()

    // state
    const [ visit, setVisit ] = useState(false)
    const [ pest, setPest ] = useState(false)
    const [ load, setLoad ] = useState(true)
    const [ loadQuestion, setLoadQuestion ] = useState(true)
    const [ loadServices, setLoadServices ] = useState(true)
    const [ loadPrestadores, setLoadPrestadores ] = useState(true)
    const [ loadPrestadoresSelect, setLoadPrestadoresSelect ] = useState(false)
    const [ loadServicesSelect, setLoadServicesSelect ] = useState(false)
    const [ loadButton, setLoadButton ] = useState(false)
    const [ data, setData ] = useState<any>(null)
    const [ total, setTotal ] = useState<any>(null)
    const [ open, setOpen ] = useState<boolean>(false)
    const [ openPrestador, setOpenPrestador ] = useState<boolean>(false)
    const [ search, setSearch ] = useState<string>('')
    const [ searchPrestador, setSearchPrestador ] = useState<string>('')
    const [ question, setQuestion ] = useState<any[]>([])
    const [ prestadores, setPrestadores ] = useState<any[]>([])
    const [ prestadoresSelected, setPrestadoresSelected ] = useState<any[]>([])
    const [ services, setServices ] = useState<any[]>([])
    const [ servicesSelected, setServicesSelected ] = useState<any[]>([])

    const [ openPragas, setOpenPragas ] = useState<boolean>(false)
    const [ searchPragas, setSearchPragas ] = useState<string>('')
    const [ pragas, setPragas ] = useState<any[]>([])
    const [ pragasSelected, setPragasSelected ] = useState<any[]>([])
    const [ loadPragas, setLoadPragas ] = useState(true)
    const [ loadPragasSelect, setLoadPragasSelect ] = useState(false)
    
    // form
    const [ form ] = Form.useForm()
    const [ formQuestion ] = Form.useForm()
    const [ client, setClient ] = useState<any>(null)
    const [ paymentMethod, setPaymentMethod ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        onLoadQuestion()
        if (type === 'add') { 
            form.setFieldValue('price', 0)
            form.setFieldValue('discount', 0)
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                    form.setFieldsValue(res.data)
                    formQuestion.setFieldsValue(res.data.info)
                    setClient({id: res.data.client_id})
                    setPaymentMethod({id: res.data.payment_method_id})
                    setServicesSelected(res.data.services)
                    setPrestadoresSelected(res.data.tecnica)
                    setPragasSelected(res.data.pragas)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = ID
        values.info = formQuestion.getFieldsValue()
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onLoadServices = () => {
        setLoadServices(true)
        POST_API(`/services/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 10}), sorter: JSON.stringify({selectColumn: 'services.name', order: 'ASC'}), search, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setServices(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadServices(false))
    }

    const onLoadPrestador = () => {
        setLoadPrestadores(true)
        POST_API(`/user/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 10}), sorter: JSON.stringify({selectColumn: 'user.name', order: 'ASC'}), search: searchPrestador, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPrestadores(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadPrestadores(false))
    }

    const onLoadPragas = () => {
        setLoadPragas(true)
        POST_API(`/target_pest/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 10}), sorter: JSON.stringify({selectColumn: 'target_pest.name', order: 'ASC'}), search: searchPragas, type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPragas(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadPragas(false))
    }

    const onLoadQuestion = () => {
        setLoadQuestion(true)
        POST_API(`/question/search.php`, { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setQuestion(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoadQuestion(false))
    }

    const cancelService = (index: any) => {
        setLoadServicesSelect(true)
        var temp = servicesSelected;
        temp.splice(index, 1)
        setServicesSelected(temp)
        setTimeout(() => {
            setLoadServicesSelect(false)
            loadValue()
        }, 1);
    }

    const changeService = (value:boolean , index: any, field: any) => {
        setLoadServicesSelect(true)
        var temp = servicesSelected;
        temp[index][field] = value ? '1' : '0'
        setServicesSelected(temp)
        setTimeout(() => {
            setLoadServicesSelect(false)
            validVisit()
            validPest()
            loadValue()
        }, 1);
    }

    const validVisit = () => {
        var visit = servicesSelected.filter((v) => v.has_technical_visit === '1')
        setVisit(visit.length > 0)
    }

    const validPest = () => {
        var pest = servicesSelected.filter((v) => v.has_target_pest === '1')
        setPest(pest.length > 0)
    }

    const cancelPrestador = (index: any) => {
        setLoadPrestadoresSelect(true)
        var temp = prestadoresSelected;
        temp.splice(index, 1)
        setPrestadoresSelected(temp)
        setTimeout(() => {
            setLoadPrestadoresSelect(false)
        }, 1);
    }

    const cancelPragas = (index: any) => {
        setLoadPragasSelect(true)
        var temp = pragasSelected;
        temp.splice(index, 1)
        setPragasSelected(temp)
        setTimeout(() => {
            setLoadPragasSelect(false)
        }, 1);
    }

    const setValue = (value:string, index:number) => {
        const updatedData = servicesSelected.map((item, idx) => 
            idx === index ? { ...item, price: Number(value) } : item
        );
        setServicesSelected(updatedData)
    }

    const loadValue = () => {
        setTotal(servicesSelected.reduce((sum, item) => Number(sum) + Number(item.price), 0))
        form.setFieldValue('price', servicesSelected.reduce((sum, item) => Number(sum) + Number(item.price), 0))
    }

    useEffect(() => {
        form.setFieldValue('total', form.getFieldValue('price')-form.getFieldValue('discount'))
    }, [total])

    useEffect(() => {
        onLoadServices()
    }, [search])

    useEffect(() => {
        onLoadPrestador()
    }, [searchPrestador])

    useEffect(() => {
        onLoadPragas()
    }, [searchPragas])

    useEffect(() => {
        validVisit()
        validPest()
        loadValue()
        setOpen(false)
        form.setFieldValue('services', servicesSelected)
    }, [servicesSelected])

    useEffect(() => {
        setOpenPrestador(false)
        form.setFieldValue('tecnica', prestadoresSelected)
    }, [prestadoresSelected])

    useEffect(() => {
        setOpenPragas(false)
        form.setFieldValue('pragas', pragasSelected)
    }, [pragasSelected])

    return (
        <PageDefaultComponent title="Gerenciamento de Orçamentos" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                    <Row gutter={[8,8]}>
                        <Col md={24} xs={24}>
                            <CardAdminComponent title="Orçamentos" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                                <Row justify={'end'} gutter={[8,8]}>
                                    <TableReturnButton type={type} permission={permission} />
                                </Row>
                            }>
                                <Form disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                    <Row gutter={[8,0]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="client_id" label='Cliente' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <SelectSearchComponent disabled={type === 'view'} placeholder="Cliente" effect={client} value={form.getFieldValue('client_id')} url="/client/select.php" change={(v:any) => form.setFieldValue('client_id', v)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="services" label="Serviços" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <List
                                                    header={
                                                        <Row justify={'space-between'} align={'middle'}>
                                                            <Col><Typography style={{fontWeight: 600}}>Serviços selecionados</Typography></Col>
                                                            <Col><Button onClick={() => setOpen(true)} type="primary" className="btn-primary" shape="round" size="small">Selecionar novo</Button></Col>
                                                        </Row>
                                                    }
                                                    size="small"
                                                    bordered
                                                    locale={{emptyText: 'Nenhum serviço selecionado'}}
                                                    dataSource={servicesSelected}
                                                    loading={loadServicesSelect}
                                                    renderItem={(item, index) => (
                                                        <List.Item key={item.id}>
                                                            <Row gutter={[8,8]} style={{width: '100%'}} align={'middle'}>
                                                                <Col md={12} xs={24}>
                                                                    <Row>
                                                                        { type === 'view' ? null : <IoClose size={20} style={{marginRight: 5}} className="list-check" onClick={() => cancelService(index)} /> }
                                                                        <List.Item.Meta title={item.name} description={(
                                                                            <Typography.Paragraph editable={{ onChange: (value) => setValue(value, index) }}>R$ {Number(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Typography.Paragraph>
                                                                        )} />
                                                                    </Row>
                                                                </Col>
                                                                <Col md={12} xs={24}>
                                                                    <Row gutter={[0,8]} justify={'end'} className="list-service">
                                                                        { item.need_technical_visit === '1' ? <Col><Switch defaultChecked={Boolean(Number(item.has_technical_visit))} onChange={(v) => changeService(v, index, 'has_technical_visit')} unCheckedChildren="Sem visita técnica" checkedChildren="Com visita técnica" style={{marginRight: 10}} /></Col> : null }
                                                                        { item.has_certificate === '1' ? <Col><Switch defaultChecked={Boolean(Number(item.need_certificate))} onChange={(v) => changeService(v, index, 'need_certificate')} unCheckedChildren="Sem certificado" checkedChildren="Com certificado" style={{marginRight: 10}} /></Col> : null }
                                                                        { item.has_report === '1' ? <Col><Switch defaultChecked={Boolean(Number(item.need_report))} onChange={(v) => changeService(v, index, 'need_report')} unCheckedChildren="Sem relatório" checkedChildren="Com relatório" style={{marginRight: 10}} /></Col> : null }
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        { pest ? (
                                            <Col xs={24} md={24}>
                                                <Form.Item name="pragas" label="Pragas alvo">
                                                    <List
                                                        header={
                                                            <Row justify={'space-between'} align={'middle'}>
                                                                <Col><Typography style={{fontWeight: 600}}>Pragas alvo selecionadas</Typography></Col>
                                                                <Col><Button onClick={() => setOpenPragas(true)} type="primary" className="btn-primary" shape="round" size="small">Selecionar novo</Button></Col>
                                                            </Row>
                                                        }
                                                        size="small"
                                                        bordered
                                                        locale={{emptyText: 'Nenhuma praga alvo selecionada'}}
                                                        dataSource={pragasSelected}
                                                        loading={loadPragasSelect}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={item.id}>
                                                                <List.Item.Meta title={item.name} />
                                                                { type === 'view' ? null : <IoClose size={20} className="list-check" onClick={() => cancelPragas(index)} /> }
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        { visit ? (
                                            <Col xs={24} md={24}>
                                                <Form.Item name="tecnica" label="Quem fará a visita técnica?" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                    <List
                                                        header={
                                                            <Row justify={'space-between'} align={'middle'}>
                                                                <Col><Typography style={{fontWeight: 600}}>Prestadores selecionados</Typography></Col>
                                                                <Col><Button onClick={() => setOpenPrestador(true)} type="primary" className="btn-primary" shape="round" size="small">Selecionar novo</Button></Col>
                                                            </Row>
                                                        }
                                                        size="small"
                                                        bordered
                                                        locale={{emptyText: 'Nenhum prestador selecionado'}}
                                                        dataSource={prestadoresSelected}
                                                        loading={loadPrestadoresSelect}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={item.id}>
                                                                <List.Item.Meta title={item.name} description={item.user_type_name} />
                                                                <Switch disabled={true} defaultChecked={Boolean(Number(item.was_present))} unCheckedChildren="Não presente" checkedChildren="Presente" style={{marginRight: 10}} />
                                                                { type === 'view' ? null : <IoClose size={20} className="list-check" onClick={() => cancelPrestador(index)} /> }
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : null }
                                        <Col xs={24} md={6}>
                                            <Form.Item name="scheduled_execution_date" label='Data prevista de execução' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <Input placeholder='Data Prevista de Execução' type="datetime-local"/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item name="price" label='Valor' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <InputNumber readOnly onChange={setTotal} style={{width: '100%'}} addonBefore="R$" placeholder='Valor'/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item name="discount" label='Desconto'>
                                                <InputNumber style={{width: '100%'}} addonBefore="R$" placeholder='Desconto' onChange={setTotal}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item name="total" label='Valor total'>
                                                <InputNumber readOnly style={{width: '100%'}} addonBefore="R$" placeholder='Valor'/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="payment_method_id" label='Forma de pagamento' rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                <SelectSearchComponent disabled={type === 'view'} placeholder="Forma de pagamento" effect={paymentMethod} value={form.getFieldValue('payment_method_id')} url="/payment_method/select.php" change={(v:any) => form.setFieldValue('payment_method_id', v)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <Form.Item name="description" label="Observações">
                                                <Input.TextArea placeholder='Observações' rows={4}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Informações">
                                                <Form layout="vertical" form={formQuestion}>
                                                    <List
                                                        header={
                                                            <Row justify={'space-between'} align={'middle'}>
                                                                <Col><Typography style={{fontWeight: 600}}>Informações adicionais</Typography></Col>
                                                            </Row>
                                                        }
                                                        size="small"
                                                        bordered
                                                        locale={{emptyText: 'Nenhuma pergunta encontrada'}}
                                                        dataSource={question}
                                                        loading={loadQuestion}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={item.id}>
                                                                <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                                { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                                    item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                                    item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                                </Form.Item>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Form>
                                            </Form.Item>
                                        </Col>
                                        { visit ? data?.services?.map((v:any, i:number) => v.has_technical_visit === '1' ? (
                                            <Col xs={24} md={24}>
                                                <Form.Item label={`${v.name} - visita técnica`}>
                                                    <Form layout="vertical" initialValues={v.info} disabled={true}>
                                                        <List
                                                            header={
                                                                <Row justify={'space-between'} align={'middle'}>
                                                                    <Col><Typography style={{fontWeight: 600}}>Informações visita técnica</Typography></Col>
                                                                </Row>
                                                            }
                                                            dataSource={v.question}
                                                            bordered
                                                            locale={{emptyText: 'Nenhuma informação adicional encontrada'}}
                                                            renderItem={(item:any, index) => (
                                                                <List.Item key={item.id}>
                                                                    <Form.Item name={`question#${item.id}`} label={item.question}  style={{width: '100%', margin: 0}}>
                                                                    { item.type_question === 'open' ? <Input.TextArea rows={4} style={{width: '100%'}} /> : 
                                                                        item.type_question === 'close' ? <Radio.Group> { item.answers.map((v:any, i:any) => <Radio value={v} key={i}>{v}</Radio> ) } </Radio.Group> :
                                                                        item.type_question === 'multiple' ? <Checkbox.Group> { item.answers.map((v:any, i:any) => <Checkbox value={v} key={i}>{v}</Checkbox> ) } </Checkbox.Group> : null }
                                                                    </Form.Item>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Form>
                                                </Form.Item>
                                            </Col>
                                        ) : null ) : null }
                                        { type === 'view' ? null :
                                            <Col span={24}>
                                                <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                                <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Form>
                            </CardAdminComponent>
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
            <Drawer open={open} onClose={() => setOpen(false)} title="Selecionar serviço" destroyOnClose>
                <List
                    header={ <Row justify={'space-between'} align={'middle'}> <Col span={24}><Input onChange={(v) => setSearch(v.target.value)} placeholder="Pesquise aqui"/></Col> </Row> }
                    size="small"
                    locale={{emptyText: loadServices ? <Spin/> : 'Nenhum serviço encontrado'}}
                    dataSource={services}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta title={item.name} description={item.price_name} />
                            <FaCheck className="list-check" onClick={() => setServicesSelected([...servicesSelected, {...item, need_certificate: '0', has_technical_visit: '0', need_report: '0'}])} />
                        </List.Item>
                    )}
                />
            </Drawer>
            <Drawer open={openPrestador} onClose={() => setOpenPrestador(false)} title="Selecionar prestador" destroyOnClose>
                <List
                    header={ <Row justify={'space-between'} align={'middle'}> <Col span={24}><Input onChange={(v) => setSearchPrestador(v.target.value)} placeholder="Pesquise aqui"/></Col> </Row> }
                    size="small"
                    locale={{emptyText: loadPrestadores ? <Spin/> : 'Nenhum prestador encontrado'}}
                    dataSource={prestadores}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta title={item.name} description={item.user_type_name} />
                            <FaCheck className="list-check" onClick={() => setPrestadoresSelected([...prestadoresSelected, item])} />
                        </List.Item>
                    )}
                />
            </Drawer>
            <Drawer open={openPragas} onClose={() => setOpenPragas(false)} title="Selecionar praga alvo" destroyOnClose>
                <List
                    header={ <Row justify={'space-between'} align={'middle'}> <Col span={24}><Input onChange={(v) => setSearchPragas(v.target.value)} placeholder="Pesquise aqui"/></Col> </Row> }
                    size="small"
                    locale={{emptyText: loadPragas ? <Spin/> : 'Nenhuma praga alvo encontrada'}}
                    dataSource={pragas}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta title={item.name} />
                            <FaCheck className="list-check" onClick={() => setPragasSelected([...pragasSelected, item])} />
                        </List.Item>
                    )}
                />
            </Drawer>
        </PageDefaultComponent>
    )

}

export default OrcamentosForm;
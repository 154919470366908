// BIBLIOTECAS REACT
import { Button, Col, InputNumber, Modal, message } from "antd"
import { Link } from "react-router-dom"

// SERVIÇOS
import { POST_API, POST_CATCH, getToken, verifyConfig } from "../../services";

// ICONES
import { IoTrashOutline } from "react-icons/io5"
import { TbArrowBack, TbBan, TbBarrierBlock, TbChecks, TbClipboardCheck, TbClipboardList, TbDashboard, TbEdit, TbEye, TbFileAnalytics, TbFiles, TbFolderOpen, TbGrid4X4, TbHomeSearch, TbPasswordUser, TbThumbUp, TbTrash, TbUsers } from "react-icons/tb"
import { ExclamationCircleOutlined } from '@ant-design/icons';

// INTERFACE
interface TableButtonInterface {
    type: 'list' | 'trash' | 'add' | 'edit' | 'view',
    permission: number[],
    item?: any,
    path?: string,
    action?: any,
}

export const TableNewButton = ( { type, permission } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to="novo"><Button size="small" type='primary' className="btn-primary" shape="round">Novo registro</Button></Link></Col>
    }
    return null
}

export const TableTrashButton = ( { type, permission } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to="lixeira"><Button size="small" shape="circle" type='text' className="page-default-button"><IoTrashOutline /></Button></Link></Col>
    }
    return null
}

export const TableReturnButton = ( { type, permission } : TableButtonInterface ) => {
    if ( (type === 'trash' || type === 'add' || type === 'edit' || type === 'view') && verifyConfig(permission) ) {
        return <Col><Link to={'..'}><Button size="small" shape="round" type="default" className="btn-default">Voltar</Button></Link></Col>
    }
    return null
}

export const TableTrEyeButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)}><TbEye title="Visualizar" size={18} color="#0dcaf0" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrTrapButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/armadilhas'}><TbGrid4X4 title="Armadilhas" size={18} color="#9b1118" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrReportButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/relatorio'}><TbFileAnalytics title="Perguntas relatório" size={18} color="#ff9807" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrTechnicalVisitButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/visitatecnica'}><TbClipboardList title="Perguntas visita técnica" size={18} color="#198754" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrEditButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/editar'}><TbEdit title="Editar" color='#0d6efd' size={18} className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrCardButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/editar'}><TbUsers title="Prestadores" color='#198754' size={18} className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrVisitButton = ( { type, permission, item } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/visita'}><TbHomeSearch title="Visita técnica" color='#198754' size={18} className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrTrashButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Deletar registro?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/${path}/del.php`, { token: getToken(), id: item.id, type: 'del' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbTrash title="Deletar" onClick={onAction} size={18} color="#a52834" className="actions-button"/></Col>
    }
    return null
}

export const TableTrStartButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {

        if (!navigator.geolocation) { Modal.warning({ title: 'Ops', content:'Seu navegador não suporta geolocalização' }) }
        navigator.geolocation.getCurrentPosition((position) => {
            Modal.confirm({
                title: 'Você tem certeza?', content: 'Você irá iniciar esta ordem de serviço!', icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, iniciar!',
                onOk() {
                    POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: item.id, status_execution: 'started', position: position.coords }), type: 'edit' }).then(rs => rs.json()).then(res => {
                        if (res.return) {
                            message.success({ content: res.msg, key: 'screen' })
                            action()
                        } else {
                            Modal.warning({ title: 'Algo deu errado', content: res.msg })
                        }
                    }).catch(POST_CATCH)
                },
                onCancel() {},
            })
        }, () => Modal.warning({ title: 'Algo deu errado', content: 'Erro ao rastrear localização' }))
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbClipboardList title="Iniciar execução" onClick={onAction} size={18} color="#ff9807" className="actions-button"/></Col>
    }
    return null
}

export const TableTrFinishButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/execucao'}><TbClipboardCheck title="Execução" size={18} color="#a52834" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrDocumentButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {
    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><Link to={String(item.id)+'/documentos'}><TbFiles title="Documentos" size={18} color="#378cdb" className="actions-button"/></Link></Col>
    }
    return null
}

export const TableTrCancelButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Você tem certeza?', content: 'Você irá cancelar este orçamento!', icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, cancelar!',
            onOk() {
                POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: item.id, status: 'canceled' }), type: 'edit' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbBan title="Cancelar" onClick={onAction} size={18} color="#a52834" className="actions-button"/></Col>
    }
    return null
}

export const TableTrReOpenButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Você tem certeza?', content: 'Você irá reabrir este orçamento!',icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, reabrir!',
            onOk() {
                POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: item.id, status: 'waiting' }), type: 'edit' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbFolderOpen title="Reabrir" onClick={onAction} size={18} color="#ff9807" className="actions-button"/></Col>
    }
    return null
}

export const TableTrApproveButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Você tem certeza?', content: "Você irá aprovar este orçamento e gerar uma ordem de serviço!", icon: <ExclamationCircleOutlined />, cancelText: 'Não, desisti!', okText: 'Sim, aprovar!',
            onOk() {
                POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify({ ID: item.id, status: 'approved' }), type: 'edit' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbThumbUp title="Aprovar" onClick={onAction} size={18} color="#198754" className="actions-button"/></Col>
    }
    return null
}

export const TableTrStockButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Atualizar estoque', content: <InputNumber addonAfter={item.unit_measurement_acronym} style={{width: '100%'}} defaultValue={item.stock} id="inputStock" />, icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Atualizar',
            onOk() {
                POST_API(`/${path}/save.php`, { token: getToken(), type: 'edit', master: JSON.stringify({ ID: item.id, stock: document.getElementById('inputStock')?.ariaValueNow }) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) ) {
        return <Col><TbDashboard onClick={onAction} size={18} color="#ff9807" className="actions-button"/></Col>
    }
    return null
}

export const TableTrRecuseButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Recusar pedido?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/${path}/recuse.php`, { token: getToken(), id: item.id, type: 'del' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) && item.STATUS === 'AR' ) {
        return <Col><TbBarrierBlock onClick={onAction} size={18} className="actions-button"/></Col>
    }
    return null

}

export const TableTrAcceptButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE DELETE
    const onAction = () => {
        Modal.confirm({
            title: 'Aceitar pedido?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/${path}/accept.php`, { token: getToken(), id: item.id, type: 'del' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' && verifyConfig(permission) && item.STATUS === 'AR' ) {
        return <Col><TbChecks onClick={onAction} size={18} className="actions-button"/></Col>
    }
    return null

}

export const TableTrRecoverButton = ( { type, permission, item, path, action } : TableButtonInterface ) => {

    // AÇÃO DE RECOVER
    const onAction = () => {
        Modal.confirm({
            title: 'Recuperar registro?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/${path}/del.php`, { token: getToken(), id: item.id, type: 'rec' }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'trash' && verifyConfig(permission) ) {
        return <Col><TbArrowBack onClick={onAction} size={18} className="actions-button"/></Col>
    }
    return null
}

export const TableTrPassword =  ( { type, item, path, action } : TableButtonInterface ) => {

    // AÇÃO RESTAURAR SENHA
    const onAction = () => {
        Modal.confirm({
            title: 'Restaurar senha?', icon: <ExclamationCircleOutlined />, cancelText: 'Não', okText: 'Sim',
            onOk() {
                POST_API(`/${path}/recpass.php`, { token: getToken(), id: item.id }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        action()
                    } else {
                        Modal.warning({ title: 'Algo deu errado', content: res.msg })
                    }
                }).catch(POST_CATCH)
            },
            onCancel() {},
        })
    }

    if ( type === 'list' ) {
        return <Col><TbPasswordUser onClick={onAction} size={18} className="actions-button"/></Col>
    }
    return null

}
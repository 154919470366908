// react libraries
import { Col, Row, Tag } from "antd";
import { useState } from "react";

// services
import { ADM, CLN, GRN, PageDefaultProps, REP, verifyConfig } from "../../../services";

// components
import TableComponent from "../../../components/TableComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableNewButton, TableTrCancelButton, TableTrApproveButton, TableTrEditButton, TableTrEyeButton, TableTrReOpenButton, TableTrTrashButton } from "../../../components/TableComponent/buttons";

const Orcamentos = ( { type, path, permission } : PageDefaultProps ) => {

    // states
    const [ action, setAction ] = useState(false);

    // table columns
    const column = [
        { title: 'Código', dataIndex: 'id', table: 'budget.id', width: '100px', sorter: true, align: 'center', render: null },
        { title: 'Cliente', dataIndex: 'client_name', table: 'client.name', width: 'auto', minWidth: '200px', sorter: true, align: 'left', render: null },
        { title: 'Previsão Execução', dataIndex: 'scheduled_execution_date_format', table: 'budget.scheduled_execution_date', width: '180px', sorter: true, align: 'center', render: null },
        { title: 'Valor', dataIndex: 'total_name', table: '( budget.price - budget.discount )', width: '140px', sorter: true, align: 'right', render: null },
        { title: 'Status', dataIndex: 'status', width: '180px', sorter: false, align: 'center', render: (item:any) => ( <Row justify={'center'} style={{width: '100%'}}><Tag style={{margin: 0}} color={item.status_color}>{item.status_name}</Tag></Row> ) },
        { title: 'Ações', dataIndex: null, width: '140px', sorter: false, align: 'center', render: (item: any) => (
            <Row justify={'center'} style={{width: '100%'}}>
                <TableTrEyeButton type={type} permission={[ADM,GRN,CLN,REP]} item={item} />
                { item.status === 'waiting' || item.status === 'technical_visit' || verifyConfig([ADM]) ? <TableTrEditButton type={type} permission={[ADM,GRN,REP]} item={item} /> : null }
                { item.status === 'waiting' ? <TableTrCancelButton type={type} permission={[ADM,GRN]} item={item} action={() => setAction(!action)} path={path} /> : null }
                { item.status !== 'waiting' && item.status !== 'technical_visit' ? <TableTrReOpenButton type={type} permission={[ADM,GRN]} item={item} action={() => setAction(!action)} path={path} /> : null }
                { item.status === 'waiting' ? <TableTrApproveButton type={type} permission={[ADM,GRN]} item={item} action={() => setAction(!action)} path={path} /> : null }
                { <TableTrTrashButton type={type} permission={[ADM,GRN]} item={item} path={path} action={() => setAction(!action)} /> }
            </Row>
        ) },
    ]

    return (
        <PageDefaultComponent title="Gerenciamento de Orçamentos" items={[
            { title: type === 'list' ? 'Lista' : 'Lixeira' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    <CardAdminComponent title="Orçamentos" subtitle={type === 'list' ? 'Lista' : 'Lixeira'} options={
                        <Row justify={'end'} gutter={[8,8]}>
                            <TableNewButton type={type} permission={permission} />
                        </Row>
                    }>
                        <TableComponent 
                            column={column}
                            path={path}
                            type={type}
                            action={action}
                            initOrder="DESC"
                        />
                    </CardAdminComponent>
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default Orcamentos;
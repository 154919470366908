// react libraries
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, List, Modal, Row, Typography, message } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken } from "../../../../services";

// components
import LoadItemComponent from "../../../../components/LoadItemComponent";
import CardAdminComponent from "../../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../../components/TableComponent/buttons";
import { IoClose } from "react-icons/io5";

const ArmadilhasForm = ( { type, path, permission } : PageDefaultProps ) => {

    // route responsible
    const navigate = useNavigate()
    
    // params 
    const { ID, TRAP } = useParams()

    // state
    const [ load, setLoad ] = useState(true)
    const [ loadButton, setLoadButton ] = useState(false)
    const [ sectors, setSectors ] = useState<any[]>([])
    const [ sectorsLoad, setSectorsLoad ] = useState<boolean>(true)
    const [ situations, setSituations ] = useState<any[]>([])
    const [ situationsLoad, setSituationsLoad ] = useState<boolean>(true)

    // form
    const [form] = Form.useForm()

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { setLoad(false); setSectorsLoad(false); setSituationsLoad(false) } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: TRAP }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    form.setFieldsValue(res.data)
                    setSectors(res.data.sectors)
                    setSituations(res.data.situations)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false);setSectorsLoad(false); setSituationsLoad(false)})
        }
    }, [type, path, form, TRAP]);

    // function save
    const onSend = (values: any) => {
        setLoadButton(true)
        values.ID = TRAP
        values.client_id = ID
        POST_API(`/${path}/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                navigate('..')
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const addSector = () => {
        setSectors([...sectors, {name: '', qty: ''}])
    }

    const minusSector = (index:number) => {
        setSectors(sectors.filter((sector, idx) => idx !== index));
    }
    
    const addSituation = () => {
        setSituations([...situations, {name: '', qty: ''}])
    }

    const minusSituation = (index:number) => {
        setSituations(situations.filter((situation, idx) => idx !== index));
    }

    return (
        <PageDefaultComponent title="Gerenciamento de Armadilhas" items={[
            { title: type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <CardAdminComponent title="Armadilhas" subtitle={type === 'add' ? 'Novo registro' : type === 'view' ? 'Visualizar registro' : 'Editar registro'} options={
                            <Row justify={'end'} gutter={[8,8]}>
                                <TableReturnButton type={type} permission={permission} />
                            </Row>
                        }>
                            <Form disabled={type === 'view'} layout="vertical" onFinish={onSend} form={form}>
                                <Row gutter={[8,0]}>
                                    <Col md={8} xs={24}>
                                        <Form.Item name="abbreviation" label="Abreviação" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder='Abreviação' />
                                        </Form.Item>
                                    </Col>
                                    <Col md={16} xs={24}>
                                        <Form.Item name="name" label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input placeholder='Nome' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{marginBottom: 24}}>
                                        <List
                                            header={
                                                <Row justify={'space-between'} align={'middle'}>
                                                    <Col><Typography style={{fontWeight: 600}}>Setores</Typography></Col>
                                                    <Col><Button onClick={addSector} type="primary" className="btn-primary" shape="round" size="small">Adicionar</Button></Col>
                                                </Row>
                                            }
                                            size="small"
                                            bordered
                                            locale={{emptyText: 'Nenhum setor adicionado'}}
                                            dataSource={sectors}
                                            loading={sectorsLoad}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <Row gutter={[8,8]} style={{width: '100%'}} align={'middle'}>
                                                        <Col md={18} xs={24}>
                                                            <Form.Item name={['sectors',index,'name']} label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                                <Input placeholder="Nome do setor" addonBefore={ type === 'view' ? null : <IoClose size={20} className="list-check" onClick={() => minusSector(index)} /> } />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={6} xs={24}>
                                                            <Form.Item name={['sectors',index,'qty']} label="Nº de armadilhas" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                                <Input placeholder="Nº de armadilhas" type="number" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                    <Col span={24} style={{marginBottom: 24}}>
                                        <List
                                            header={
                                                <Row justify={'space-between'} align={'middle'}>
                                                    <Col><Typography style={{fontWeight: 600}}>Situações</Typography></Col>
                                                    <Col><Button onClick={addSituation} type="primary" className="btn-primary" shape="round" size="small">Adicionar</Button></Col>
                                                </Row>
                                            }
                                            size="small"
                                            bordered
                                            locale={{emptyText: 'Nenhuma situação adicionada'}}
                                            dataSource={situations}
                                            loading={situationsLoad}
                                            renderItem={(item:any, index) => (
                                                <List.Item key={item.id}>
                                                    <Row gutter={[8,8]} style={{width: '100%'}} align={'middle'}>
                                                        <Col md={24} xs={24}>
                                                            <Form.Item name={['situations',index,'name']} label="Nome" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                                                <Input placeholder="Nome da situação" addonBefore={ type === 'view' ? null : <IoClose size={20} className="list-check" onClick={() => minusSituation(index)} /> } />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                    { type === 'view' ? null :
                                        <Col span={24}>
                                            <Button shape="round" htmlType="submit" className="btn-primary" type="primary" style={{float: 'right', marginLeft: 6}} loading={loadButton}>Salvar</Button>
                                            <Button shape="round" className="btn-default" type="default" style={{float: 'right'}} onClick={() => navigate('..')}>Cancelar</Button>
                                        </Col>
                                    }
                                </Row>
                            </Form>
                        </CardAdminComponent>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default ArmadilhasForm;